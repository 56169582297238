<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <div class="d-flex flex-column justify-space-between align-center mb-4">
            <v-img
              :width="200"
              src="@/assets/images/logo.svg"
            />
          </div>
          <div class="" v-if="!registerSent">
            <h6 class="text--disabled font-weight-medium mb-10">
              Create your account
            </h6>
            <v-text-field
              v-model="email"
              label="Email"
              :rules="emailRules"
              :error-messages="emailErrors"
              required
            />
            <v-text-field 
              v-model="firstname"
              label="Firstname" 
              :error-messages="firstnameErrors"
            />
            <v-text-field 
              v-model="lastname"
              label="Lastname" 
              :error-messages="lastnameErrors"
            />
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              :counter="10"
              :rules="nameRules"
              :error-messages="passwordErrors"
              @click:append="show = !show"
            />
            <v-text-field
              v-model="confirmPassword"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Confirm Password"
              :counter="10"
              :rules="nameRules"
              :error-messages="confirmPasswordErrors"
              @click:append="show = !show"
            />
            <v-btn
              class="mt-4 mb-4"
              block
              color="primary"
              dark
              @click="setUser"
            >
              Sign Up
            </v-btn>
            <div class="">
              Do you have an account ?
              <v-btn
                text
                small
                color="primary"
                to="/"
              >
                Sign In
              </v-btn>
            </div>
          </div>
          <h6 class="text--disabled font-weight-medium mb-10" v-else>
            Registration request sent. Check your email.
          </h6>
        </v-card-text>
        <v-snackbar
          v-model="snackbar"
          top
          :color="colorSnackbar"
        >
          {{ messageSnackbar }}
          <template v-slot:action="{attrs}">
            <v-btn
              color=""
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </base-card>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import axios from 'axios'
  import { validationMixin } from 'vuelidate';
  import { required, email, minLength, sameAs, maxLength, integer, between } from 'vuelidate/lib/validators';

  export default {
    name: 'RegisterUser',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Registrierung',
    },
    mixins: [validationMixin],
    data () {
      return {
        show: false,
        password: '',
        firstname: '',
        lastname: '',
        checkbox1: true,
        checkbox2: false,
        email: '',
        confirmPassword: '',
        loading: false,
        snackbar: false,
        colorSnackbar: 'danger',
        messageSnackbar: 'Invalid Email',
        registerSent: false,
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        nameRules: [
          (v) => !!v || 'Password is required',
          (v) =>
            (v && v.length >= 6) ||
            'Name must be greater than 6 characters',
        ],
      }
    },
    methods: {
      ...mapActions(['signUserUp']),
      setUser () {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.snackbar = true;
          return;
        }
        axios.post(`${process.env.VUE_APP_SMT_API_URL}/register`, {
          Username: this.email,
          Password: this.password,
          Email: this.email,
          LastName: this.firstname,
          FirstName: this.lastname,
          URL: window.location.origin + '/app/sessions/validate-register',
          SendEmail: true
        })
        .then(response => {
          this.colorSnackbar = 'success';
          this.messageSnackbar = 'Email sent';
          this.snackbar = true;
          this.registerSent = true;
          console.log('response', response);
        })
        .catch(error => {
          this.colorSnackbar = 'danger';
          this.messageSnackbar = 'Error';
          this.snackbar = true;
        });
      },
      submit () {
        if (this.email && this.confirmPassword) {
          this.signUserUp({ email: this.email, password: this.confirmPassword })

          setTimeout(() => {
            console.log('done')
          }, 1000)
        } else {
          this.snackbar = true
        }
      },
    },
    computed: {      
      emailErrors() {
        const errors = []
        if (!this.$v.email.$dirty) {
          return errors
        }
        !this.$v.email.required && errors.push('Email is required')
        !this.$v.email.email && errors.push('Invalid Email')
        return errors
      },
      firstnameErrors() {
        const errors = []
        if (!this.$v.firstname.$dirty) {
          return errors
        }
        !this.$v.firstname.required && errors.push('Firstname is required')
        return errors
      },
      lastnameErrors() {
        const errors = []
        if (!this.$v.lastname.$dirty) {
          return errors
        }
        !this.$v.lastname.required && errors.push('Lastname is required')
        return errors
      },
      passwordErrors() {
        const errors = []
        if (!this.$v.password.$dirty) {
          return errors
        }
        !this.$v.password.required && errors.push('Password is required')
        !this.$v.password.minLength && errors.push('Password must have minimum 10 characters')
        return errors
      },
      confirmPasswordErrors() {
        const errors = []
        if (!this.$v.confirmPassword.$dirty) {
          return errors
        }
        !this.$v.confirmPassword.required && errors.push('Confirm password')
        !this.$v.confirmPassword.sameAsPassword && errors.push('Passwords do not match')
        return errors
      }
    },
    validations: {
      firstname: {
        required
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(10)
      },
      confirmPassword: { 
        required, 
        sameAsPassword: sameAs('password') 
      }
    },
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
